import './App.css'
const Footer = () => {
    return (
        <>
        <footer className="bg-light text-center">
            <p>Published work © Karan Singh Parihar. All rights reserved | Terms and conditions.</p>
        </footer>
        </>
    )
}
export default Footer;